import React from "react";
import "./auth.styles.scss";
import "../form.styles.scss";

const AuthTemplate = (props) => {
  return (
    <section className="auth">
      <div className="container">
        <header>
          <img alt="" src={window.config.logoUrl} />
        </header>
        {props.children}
        <footer>
          <p>© 2023 {window.config.name}. All Rights Reserved.</p>
        </footer>
      </div>
    </section>
  );
};

export default AuthTemplate;

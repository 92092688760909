import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import { IsValid } from '../common.service'
import { ScrollIntoViewById } from '../../../utils/UIHelper'

const getDefaultState = () => {
    return {
        saving: false,
        loadingAccount: false,
        type: "",
        firstname: "",
        lastname: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        transferStops: "",
        transferMessages: "",
        transferCodes: "",
        minimumBalance: "",
        transferCharges: "",
        photo: "",
        status: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadAccount = (instance) => {
    instance.setState({
        ...instance.state,
        loadingAccount: true,
        flag: {
            type: "",
            text: ""
        }
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/account/admin",
        data: {
            id: instance.props.match.params.id
        }
    }).then(response => handleLoadAccountResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: "Unknown Error"
                }
            })
            ScrollIntoViewById("edit")
        })
}

const handleLoadAccountResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                type: response.data.data.type,
                firstname: response.data.data.firstname,
                lastname: response.data.data.lastname,
                phone: response.data.data.phone,
                address: response.data.data.address,
                city: response.data.data.city,
                state: response.data.data.state,
                country: response.data.data.country,
                transferStops: response.data.data.transferStops,
                transferMessages: response.data.data.transferMessages,
                transferCodes: response.data.data.transferCodes,
                transferCharges: response.data.data.transferCharges,
                minimumBalance: response.data.data.minimumBalance,
                photo: response.data.data.photo,
                status: response.data.data.status,
                loadingAccount: false
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
    ScrollIntoViewById("create")
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true,
        flag: {
            type: "",
            text: ""
        }
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/accounts/admin/update",
        data: {
            id: instance.props.match.params.id,
            type: Number(instance.state.type),
            firstname: instance.state.firstname,
            lastname: instance.state.lastname,
            phone: instance.state.phone,
            address: instance.state.address,
            city: instance.state.city,
            state: instance.state.state,
            country: instance.state.country,
            transferStops: instance.state.transferStops,
            transferMessages: instance.state.transferMessages,
            transferCodes: instance.state.transferCodes,
            status: instance.state.status,
            transferCharges: instance.state.transferCharges,
            minimumBalance: instance.state.minimumBalance
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: "Unknown Error"
                }
            })
            ScrollIntoViewById("edit")
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Account Updated"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
    ScrollIntoViewById("edit")
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => IsValid(instance),
        loadAccount: () => loadAccount(instance),
        save: () => save(instance)
    }
}

export default Service
import axios from 'axios'
import { API_ENDPOINT } from '../../config'

const getDefaultState = () => {
    return {
        loading: false,
        accounts: [],
        filter: [],
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 100,
        total: 0
    }
}

const loadAccounts = instance => {
    instance.setState({ ...instance.state, loading: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/accounts",
        headers : {
            "Authorization": instance.props.auth
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: instance.state.pageSize,
            criteria: instance.state.filter
        }
    }).then(response => handleLoadAccountsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadAccountsResponse = (instance, response) => {
    switch(response.data.status){
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                selectedIndex: -1,
                accounts: response.data.data.accounts,
                total: response.data.data.size
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const deleteAccount = (instance, id) => {
    instance.setState({ ...instance.state, loading: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/account/delete",
        headers : {
            "Authorization": instance.props.auth
        },
        data: {
            id: id
        }
    }).then(response => handleDeleteAccountResponse(instance, id, response))
    .catch(error => alert(error))
}

const handleDeleteAccountResponse = (instance, id, response) => {
    switch(response.data.status){
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                accounts: instance.state.accounts.filter(account => account.id !== id)
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        loadAccounts: () => loadAccounts(instance),
        getDefaultState: () => getDefaultState(instance),
        deleteAccount: id => deleteAccount(instance, id)
    }
}

export default Service
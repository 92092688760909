import { NumberValidationRule, PhoneValidationRule, StringValidationRule } from '../../data/validation/rules'
import { ScrollIntoFirstError } from '../../utils/UIHelper'
import Validation from '../../data/validation/validation'

export const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.type, (error) => instance.state.errors.type = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    if (typeof instance.state.username !== 'undefined') {
        validation.addValidationRule(StringValidationRule, instance.state.username, (error) => instance.state.errors.username = error, { min: { value: 6, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
        validation.addValidationRule(StringValidationRule, instance.state.password, (error) => instance.state.errors.password = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })
    }
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => instance.state.errors.firstname = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => instance.state.errors.lastname = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => instance.state.errors.phone = error, { allowNull: false })
    //validation.addValidationRule(StringValidationRule, instance.state.transferStops, (error) => instance.state.errors.transferStops = error, { min: { value: 2, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    //validation.addValidationRule(StringValidationRule, instance.state.transferMessages, (error) => instance.state.errors.transferMessages = error, { min: { value: 2, error: "Invalid" }, max: { value: 600, error: "Invalid" } })
    //validation.addValidationRule(StringValidationRule, instance.state.transferCodes, (error) => instance.state.errors.transferCodes = error, { min: { value: 2, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.transferCharges, (error) => instance.state.errors.transferCharges = error, { min: { value: 0, error: "Invalid" }, max: { value: 100000000, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.minimumBalance, (error) => instance.state.errors.minimumBalance = error, { min: { value: 0, error: "Invalid" }, max: { value: 1000000000, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.status, (error) => instance.state.errors.status = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}